import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProductPreviewGrid from "../components/product-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query ProductPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          url
        }
      }
    }
    products: allSanityProduct(sort: {fields: orderRank, order: ASC}) {
      edges {
        node {
            mainImage {
              asset {
                _id
              }
              alt
            }
            _rawExcerpt
            _rawDescription
            title
            publishedAt(formatString: "YYYY")
            slug {
              current
            }
            id
          }
        
      }
    }
    
  }
`;

const ArchivePage = props => {
  const { data, errors, location } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;
  const products =
    data && data.products && mapEdgesToNodes(data.products);
  const productNodes = products
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";
  // console.log(productNodes)
  return (
  <Layout location={location}>
      <SEO title="Products" image={seoImage} />
      <Container>
        <div>
          {productNodes && productNodes.length > 0 && <ProductPreviewGrid nodes={productNodes} />}
        </div>
      </Container>
    </Layout>
  );
};

export default ArchivePage;
